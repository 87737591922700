.home {
    margin: 100px 300px;
    display: flex;
    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 50px;
        margin-right: 150px;

        .title{
            font-size: 40px;
            text-transform: capitalize;
        }

        .desc{
            font-size: 18px;
        }

        .link{
            text-decoration: none;
            .cv{
                margin-top: 20px;
                width: 150px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                background-color: #777;
                color: white;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    .right{
        display: flex;
        justify-content: center;
        img{
            border-radius: 20px;
            height: 30em;
            object-fit: cover;
        }
    }
}