.contact {
    margin: 80px 300px;

    .top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        h1{
            text-transform: capitalize;
        }
    }

    .bottom {
        display: flex;
        justify-content: center;
        gap: 100px;

        .left {
            display: flex;
            .contact-form {
                display: flex;
                flex-direction: column;
                width: 600px;
                padding: 20px;
                background-color: #FFFDF9;
                border-radius: 10px;

                input,
                textarea {
                  margin-bottom: 20px;
                  padding: 10px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                }
                
                button {
                  padding: 10px 20px;
                  background-color: #EC5D2A;
                  color: #fff;
                  border: none;
                  border-radius: 4px;
                  cursor: pointer;
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                  font-weight: 600;
                }
                
                button:hover {
                  background-color: #777;
                }
            }

        }

        .right{
            p{
                font-size: 18px;
                margin-bottom: 50px;
            }
            .icons{
                display: flex;
                margin-top: 10px;
                gap: 25px;
                font-size: 20px;
                color: #777;
                cursor: pointer;
            }
        }
    }
}