.navbar{
    height: 80px;
    font-family: 'Poppins', sans-serif;
    .wrapper{
        display: 10px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .item{
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    .left{
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: 50px;
    }

    .centre{
        letter-spacing: 2px;
        .logo{
            font-size: 30px;
            font-weight: 600;
        }
    }

    .right{
        display: flex;
        align-items: center;
        gap: 30px;
        margin-right: 50px;
    }
}