.card {
    margin: 50px 200px;
    display: flex;
    justify-content: center;
    gap: 50px;

    .left {
        .image {
            width: 400px;
            height: 250px;
            overflow: hidden;
            position: relative;
            border: none;
            border-radius: 10px;
            margin-top: 20px;

            &:hover{
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                transition: 0.2s ease-in-out;
            }
    
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }

        }

    }

    .right {
        display: flex;
        flex-direction: column;
        width: 600px;

        h2{
            margin-bottom: 10px;
        }

        .desc{
            font-size: 18px;
        }

        .icons{
            margin-top: 20px;
            display: flex;
            gap: 50px;

            .icon-link{
                text-decoration: none;
                display: flex;
                align-items: center;
                color: #777;
                font-size: 24px;
                gap: 15px;

                span{
                    font-size: 16px !important;
                }
            }
        }
    }
}