.about {
    margin: 100px 300px;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        h1{
            text-transform: capitalize;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .desc{
            font-size: 18px;
            margin-bottom: 50px;
        }
        .sub-desc{
            font-size: 18px;
        }
        .email{
            font-size: 18px;
        }
        .icons{
            display: flex;
            margin-top: 10px;
            gap: 25px;
            font-size: 20px;
            color: #777;
            cursor: pointer;
        }
    }
}